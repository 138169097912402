// @ts-nocheck
/* eslint-disable */

import React, {ReactElement, useEffect, useState} from 'react';
import {Footer} from '@/_core/footer/Footer';
import {PageLayoutHeader} from '@/_core/layout/PageLayoutHeader';
import './PageLayout.scss';
import {PageLayoutDevFooter} from '@/_core/PageLayoutDevFooter';
import {generate} from '@modules/Core/components/base/helper';
import {Group} from '@modules/Core/components/layout/Group';
import {usePageLayout} from '@modules/Core/hooks/state/pageLayout';
import {_LayoutType} from '@modules/Core/types/pageLayout.model';
import {logger} from '@modules/Core/util/Logger';

interface _PageLayout {
  children: ReactElement;
  setShowSidebar?: (show: boolean) => void;
}

// Adds a layout to every page.
// The content of every page is rendered in a card with a width of 10-columns (default) or 12-columns (no-container).
// Also introduces the group as the upmost div-container for the page defining the "pageGap" of 8 (64px) between every section of the group.

/**
 * Main App Layout
 * Renders Header with title and actions
 * Renders loader or main content
 * @param setShowSidebar
 * @param children
 * @constructor
 */
export const PageLayout: React.FC<_PageLayout> = ({setShowSidebar, children}) => {
  const {options, title, actions, subTitle, softwareReleaseKey} = usePageLayout();
  const [currentView, setCurrentView] = useState<_LayoutType>('default');
  const header = (
    <PageLayoutHeader
      options={options}
      title={title}
      subTitle={subTitle}
      softwareReleaseKey={softwareReleaseKey}
      actions={actions}
      setShowSidebar={setShowSidebar}
    />
  );
  const footer = <Footer />;
  const devFooter = <PageLayoutDevFooter />;
  const backgroundIcons = null;

  useEffect(() => {
    if (!!options?.layout && options.layout !== currentView) {
      setCurrentView(options.layout);
    }
  }, [options]);

  const [instanceKeyId] = useState<string>(`${generate()}`);
  logger.info('ZZZZZ [PageLayout] Initializing layout', {instanceKeyId, currentView});
  logger.debug('[PageLayout] title', {actions});

  const isDefaultLayout = currentView === 'default';

  return (
    <div className="flex flex-col justify-between pt-3 pb-5 h-full gap-6 page-layout">
      <div className="flex flex-grow flex-col items-start gap-3">
        {header}
        <div className={`flex ${isDefaultLayout ? 'pt-5 pb-5 rounded-sm bg-white500 shadow-3' : ''} page-layout-card`}>
          {isDefaultLayout && backgroundIcons}
          <div
            className={`relative-col-width-${isDefaultLayout ? '10' : '12'} ${isDefaultLayout ? 'page-layout-content' : ''}`}
          >
            <Group>{children}</Group>
          </div>
        </div>
      </div>
      {devFooter}
      {footer}
    </div>
  );
};
